import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./partials/Header";
import Accounts from "./pages/account/Accounts";
import Account from "./pages/account/Account";
import NewAccount from "./pages/account/NewAccount";
import EditAccount from "./pages/account/EditAccount";
import Login from "./pages/auth/Login";
import GoogleRedirection from "./pages/auth/GoogleRedirection";
import Dashboard from "./pages/dashboard/Dashboard";
import ProtectedRoute from "./services/ProtectedRoute";
import dashboardAccount from "./pages/dashboardAccount/dashboardAccount";

import "./App.scss";

function App() {
  return (
    <Router>
      <Header />
      <div className="App">
        <Switch>
          <Route path="/auth/login">
            <Login />
          </Route>
          <Route path="/auth/google">
            <GoogleRedirection />
          </Route>
          <ProtectedRoute path="/dashboard" component={Dashboard} />
          <Route path="/" exact>
            <Redirect to="/auth/login" />
           </Route>
          <ProtectedRoute path="/accounts/new" component={NewAccount} />
          <ProtectedRoute path="/accounts/edit/:id" component={EditAccount} />
          <ProtectedRoute path="/accounts/:id" component={Account} />
          <ProtectedRoute path="/accounts" component={Accounts} />
          <ProtectedRoute path="/dashboardAccount/dashboardAccount" component={dashboardAccount} />

        </Switch>
      </div>
    </Router>
  );
}

export default App;
