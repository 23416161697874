import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({setRole,...props}:any) => {
	//console.log(flag);
	if(!setRole){
		if(localStorage.token){
			return <Route {...props} />;
		}else{
			return <Redirect to="/login"/>;			
		}
	}else{
		let user = JSON.parse(localStorage.user);
		if(localStorage.token && user){
			return <Route {...props} />;
		}else{
			return <Redirect to="/login"/>;			
		}
	}
};

export default ProtectedRoute;