import React, { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { request } from "../../services/Request";

function NewAccount() {
  const [accountData, setAccuontData]: any = useState({});
  const [generatedPassword, setGeneratedPassword] = useState("");

  const onChangeHandlerFn = (event: any) => {
    let objMod: { [index: string]: any } = {};
    objMod[event.target.name] = event.target.value;
    setAccuontData({ ...accountData, ...objMod });
    if (
      event.target.name === "password" &&
      event.target.value !== generatedPassword
    ) {
      setGeneratedPassword("");
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    request("/api/account", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(accountData),
    }).then((data: any) => {
      if (data.error) {
        console.log(data.error);
      } else {
        window.location.href = "/accounts";
      }
    });
  };

  const generatePassword = (e: any) => {
    e.preventDefault();
    const password =
      Math.random().toString(36).substring(2, 8) +
      Math.random().toString(36).substring(2, 8);
    setGeneratedPassword(password);
    (document.getElementsByName(
      "password"
    )[0] as HTMLInputElement).value = password;
    let data = accountData;
    data["password"] = password;
    setAccuontData(data);
  };

  return (
    <div className="new-account">
      <Row className="justify-contend-end banner">
        <Col md={12} className="p-0">
          <div className="overlay">
            <div>
              <h1>Nueva Cuenta</h1>
            </div>
          </div>
        </Col>
      </Row>
      <div className="page-content">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Nombre de usuario</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User name"
                  name="username"
                  onChange={onChangeHandlerFn}
                />
              </Form.Group>
              <Row className="align-items-center">
                <Col xs={12} md={8}>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="password"
                      onChange={onChangeHandlerFn}
                    />
                  </Form.Group>
                  {generatedPassword.length > 0 && (
                    <p className="mt-2">
                      Contraseña generada: <b>{generatedPassword}</b>
                    </p>
                  )}
                </Col>
                <Col xs={12} md={4}>
                  <a
                    className="btn btn-secondary w-100"
                    href="#"
                    onClick={generatePassword}
                  >
                    Generar
                  </a>
                </Col>
              </Row>

              <Form.Group controlId="formBasicDatabase">
                <Form.Label>Base de datos</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="database"
                  name="database"
                  onChange={onChangeHandlerFn}
                />
              </Form.Group>
              <Form.Group controlId="engine">
                <Form.Label>Motor</Form.Label>
                <Form.Control
                  as="select"
                  name="engine"
                  onChange={onChangeHandlerFn}
                  required
                >
                  <option></option>
                  <option value="mongo">MONGODB</option>
                  <option value="mysql">6 MYSQL</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="frequency">
                <Form.Label>Frequencia</Form.Label>
                <Form.Control
                  as="select"
                  name="frequency"
                  onChange={onChangeHandlerFn}
                  required
                >
                  <option></option>
                  <option value="1">1 hora</option>
                  <option value="6">6 horas</option>
                  <option value="24">24 horas</option>
                  <option value="168">1 semana</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="dbtype">
                <Form.Label>Motor</Form.Label>
                <Form.Control
                  as="select"
                  name="dbtype"
                  onChange={onChangeHandlerFn}
                  required
                >
                  <option></option>
                  <option value="mongodb">Mongodb</option>
                  <option value="mariadb">Mariadb</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="createDatabase">
                <Form.Check
                  type="checkbox"
                  label="Create database (mongodb only)"
                  name="create"
                  onChange={onChangeHandlerFn}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default NewAccount;
