import React, {useEffect, useState} from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { request } from '../../services/Request';
import '../../styles/login.scss';

function Login() {


  const [googleUrl, setUrl] = useState("");

  useEffect(()=>{
    request('/api/auth/google',{})
    .then((result:any)=>{
      if(result.data){
        setUrl(result.data.url);
      }
    }).catch((error)=>{
    });
  }, []);

  return (
    <div className="login-container">
      <Row className="login-form justify-content-center align-items-center">
         <Col xs={12} md={6} lg={4}>
           <Card>
             <Card.Title>
               <h2 className="mt-3 mb-3">Iniciar sesión</h2>
             </Card.Title>
             <Card.Body>
               <Row>
                 <Col>                   
                   <a className="btn btn-danger mt-3 mb-3 w-100 text-center" href={googleUrl}>Iniciar sesión con Google</a>
                 </Col>
               </Row>
             </Card.Body>
           </Card>
         </Col>
      </Row>
    </div>
  );
}

export default Login;
