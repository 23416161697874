import { listenerCount } from "process";
import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { request } from "../../services/Request";

function Accounts() {

  const [accounts, setAccounts]: any = useState([]);
  useEffect(() => {
    accountList();
  },);

  const showStatus = (account:any) => {
    let flag = false;
    let frequencyHour = 3600000 * account.frequency;
    let lastBackup = new Date(account.last_backup);
    let currentDate = new Date();
    if (currentDate.getTime() - lastBackup.getTime() < frequencyHour){
      flag = true;
    }
    return flag;
  };
  const accountList = function () {
    request("/api/account", {
      method:"get",
    }).then((result: any) => {      
      setAccounts(result.data);      
    });
    
  };


  const deleteaccount = function (id:string) {
    request("/api/account/deleteaccount/"+id, {
      method: 'delete'
    }).then((resp:any) => {
      if(resp.error){
        alert("No se elimino el proyecto");
      }
      else{
        accountList();
      }
    });
  };


  return (
    <div className="accounts">
      <Row className="justify-contend-end banner">
        <Col md={12} className="p-0">
          <div className="overlay">
            <div>
              <h1>Cuentas</h1>
            </div>
            <Link to="/accounts/new">Nueva cuenta</Link>
          </div>
        </Col>
      </Row>
      <div className="page-content">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Usuario</th>
                  <th>Base de datos</th>
                  <th>Motor</th>
                  <th>Último respaldo</th>
                  <th>Estatus</th>
                  <th>Detalle</th>
                </tr>
              </thead>
              <tbody>
                {accounts.map((account: any, i: number) => (
                  <tr key={i}>
                    <td>{account.username}</td>
                    <td>{account.database}</td>
                    <td>{account.dbtype}</td>
                    <td>{account.last_backup}</td>
                    <td>
                      { showStatus(account) ? (
                          <span className="text-success">Respaldado</span>
                        ): (
                          <span className="text-danger">Sin respaldar</span>
                        ) }  
                     </td>
                    <td>
                      <Link
                        to={`/accounts/${account._id}`}
                        className="btn btn-secondary ml-2"
                      >
                        Ver
                      </Link>
                      <Link
                        to={`/accounts/edit/${account._id}`}
                        className="btn btn-secondary ml-2"
                      >
                        Editar
                      </Link>
                      &nbsp; &nbsp;
                      <Button 
                        variant="secondary"
                        onClick={() => deleteaccount(account._id)}
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Accounts;
