import React from "react";
import { Navbar, Nav } from "react-bootstrap";

function Header() {
  return (
    <header className="header fixed-top">
      <Navbar collapseOnSelect expand="md">
        <Navbar.Brand href="/">DB Manager</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/accounts">Cuentas</Nav.Link>
            <Nav.Link href="/dashboardAccount/dashboardAccount">Resumen De Proyectos</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
