import React, {useEffect, useState} from 'react';
import { request } from '../../services/Request';

function GoogleRedirection() {

  const [googleUrl, setUrl] = useState("");

  useEffect(()=>{
    let urlParams = new URLSearchParams(window.location.search);
    request('/api/auth/signin',{
      method: 'POST',
      body: JSON.stringify({code: urlParams.get('code')}),
      headers: { "Content-Type": "application/json" },
    })
    .then((result:any)=>{
      if(result.data){
        localStorage.token = result.data.token;
        localStorage.user = result.data.user;
        window.location.href = "/dashboard"; 
      }else{
        window.location.href = "/auth/login";  
      }
    }).catch((error)=>{
      console.log(error);
      window.location.href = "/auth/login";
    });
  }, []);

  return (
    <div className="login-container">
    </div>
  );
}

export default GoogleRedirection;
