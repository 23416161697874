import { listenerCount } from "process";
import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { request } from "../../services/Request";


function Abstract() {
  const [abstract, setAbstract]: any = useState([]);
  useEffect(() => {
    backupListabstract();
  },);

  const Sizebackup = (weight: any) => {
    const unit = ['Bytes', 'KB', 'MB', 'GB'];
    if (weight === 0){
      return 'n/a';
    }     
    const size = Math.floor(Math.log(weight) / Math.log(1024));
    if (size === 0){
      return `${weight} ${unit[size]}`;
    }
    return `${(weight / (1024 ** size)).toFixed(2)} ${unit[size]}`;
  };

  const backupListabstract = function () {
    request("/api/backup", {
      method:"get",
    }).then((result: any) => {      
      setAbstract(result.data);      
    });
    
  };

  const showStatus = (account:any) => {
    console.log(account)
    let flag = false;
    let frequencyHour = 3600000 * account.frequency;
    let lastBackup = new Date(account.last_backup);
    let currentDate = new Date();
    if (currentDate.getTime() - lastBackup.getTime() < frequencyHour){
      flag = true;
    }
    return flag;
  }

  return (
  <div className="dashboard-container">
    <h1>DASHBOARD</h1>
    <h2>Resumen General</h2>
    <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Cantidad de Respaldos</th>
                  <th>Tamaño</th>
                  <th>Fecha y Hora de Creación</th>
                  <th>Estado del Proyecto</th>
                </tr>
              </thead>
              <tbody>
                {abstract.map((account: any, i: number) => (
                  <tr  key={i}>
                    <td>{account.database}</td>
                    <td>{account.totalBackups}</td>
                    <td>{Sizebackup(account.size_backup)}</td>
                    <td>{account.created_at}</td>
                    <td>
                    {showStatus(account) ? (
                        <span className="text-success">Respaldado</span>
                     ): (
                        <span className="text-danger">Sin respaldar</span>
                    )}  
                     </td>
                  </tr>
                ))}
              </tbody>            
            </Table>
          </Col>
        </Row>
  </div>
  


  );
}
export default Abstract;