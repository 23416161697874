import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button, Modal, Toast, ProgressBar } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { request } from "../../services/Request";
import { blob } from "../../services/Blob";
import axios from "axios";
import { isTemplateExpression } from "typescript";
import { listenerCount } from "process";

function Account() {  
  const [account, setAccount]: any = useState({}); 
  const [backups, setBackups]: any = useState([]);
  const [modal, showModal] = useState(false);
  const [toastFlag, setToastFlag] = useState(false);
  const [file, setFile]: any = useState({});
  const [progress, setProgress] = useState(0);
  const [disableButton, setDisable] = useState(false);
  const [toastBody, setToastBody]:any = useState({
    title:'',
    message:''
  });
  const [password, setPassword] = useState('');

  const { id } : any = useParams();

  useEffect(() => {
    request("/api/account/" + id, {}).then((result: any) => {
      setAccount(result.data);
    });
    backupList();
  }, [id]);

  const showPassword = function (event:any) {
    request("/api/account/password/"+id, {
    }).then((resp:any) => {
      if(resp.data && resp.data.password){
        setPassword(resp.data.password);
      }
    });
  }
  
  const createBackup = function (event: any) {
    setDisable(true);
    request("/api/backup", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(account),
    }).then((resp:any) => {          
      if(resp.error){
        setToastBody({
          title: 'Error de Respaldo',
          message: 'Error el respaldo no se cargo '
        });
      }else{
        setToastBody({
          title: 'Crear respaldo',
          message: 'Cargando el respaldo...'
        });
      }
      setToastFlag(true);
      backupList();
      setDisable(false);
    }).catch((error)=>{
      setToastBody({
        title: 'Cargar respaldo',
        message: 'Hubo un error al crear el respaldo'
      });
      setToastFlag(true);
      setDisable(false);
    });
  };

  const confirmUpload = () => {
    //setLoading(true);
    setDisable(true);
    const data = new FormData();
    data.append("file", file);
    data.append("accountId", id);
    axios
      .request({
        method: "post",
        url: "/api/backup/load",
        data: data,
        headers: {'Authorization': `Bearer `+localStorage.token},
        onUploadProgress: (p: any) => {
          setProgress((p.loaded / p.total) * 100);
        },
      })
      .then((data: any) => {
        showModal(false);
        setToastBody({
          title: 'Cargar respaldo',
          message: 'Se subió el respaldo con éxito'
        });
        setToastFlag(true);
        setDisable(false);
      })
      .catch((error) => {
        setToastBody({
          title: 'Cargar respaldo',
          message: 'Hubo un error al cargar el respaldo'
        });
        setToastFlag(true);
        setDisable(false);
      });
  };
  
  const fileHandle = (event: any) => {
    setFile(event.target.files[0] as HTMLInputElement);
  };

  const uploadFile = () => {
    (document.getElementById("file-input") as HTMLInputElement).click();
  };
  
  const backupList = function () {
    request("/api/backup/byaccount/" + id, {}).then((result: any) => {      
      setBackups(result.data);      
    });
    
  };

  const deletebackup = function (id:string) {
    request("/api/backup/deletebackup/"+id, {
      method:"delete",
    }).then((resp:any) => {
      if(resp.error){
        alert("No se elimino el respaldo");
      }
      else{
      backupList();
      }
    });
  };

  const getBlob = function (backupId: string, filename: string) {
    blob("/api/backup/" + backupId, {}).then((_blob) => {
      const url = window.URL.createObjectURL(
        new Blob([_blob], { type: "application/gzip" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };
    const sumTotal = () => {    
    var sum = 0;
    for(let count = 0; count<backups.length; count++){
       sum += backups[count].size;      
    }
    return (sum);
  };

  const Sizebackup = (weight: any) => {
    const unit = ['Bytes', 'KB', 'MB', 'GB'];
    if (weight === 0){
      return 'n/a';
    }     
    const size = Math.floor(Math.log(weight) / Math.log(1024));
    if (size === 0){
      return `${weight} ${unit[size]}`;
    }
    return `${(weight / (1024 ** size)).toFixed(4)} ${unit[size]}`;
  }
  const showStatus = (account:any) => {
    console.log(account)
    let flag = false;
    let frequencyHour = 3600000 * account.frequency;
    let lastBackup = new Date(account.last_backup);
    let currentDate = new Date();
    if (currentDate.getTime() - lastBackup.getTime() < frequencyHour){
      flag = true;
    }
    return flag;
  }
   
  return (
    <div className="account">
      <Row className="justify-content-end banner">
        <Col md={12} className="p-0">
          <div className="overlay">
            <div>
              <h1>Cuenta</h1>              
            </div>
          </div>
        </Col>
      </Row>
      <div className="page-content mt-3">
        {account && (
          <Row className="justify-content-center">
            <Col xs={12} md={2}>
              <p>Usuario: <b>{account.username}</b></p>
              <Button onClick={showPassword}>Mostrar contraseña</Button>
             </Col>
              { password.length > 0 &&
               <Col xs={12} md={2}>
                <p>Contraseña: <b>{password}</b></p>
               </Col>
              }
            <Col xs={12} md={2}>Base de datos: <b>{account.database}</b></Col>
            <br/>
            <Col xs={12} md={2}>Tamaño y Peso total de respaldos: <b>{Sizebackup(sumTotal())}</b></Col>
            <Col xs={12} md={2}>Fecha de creación: <b>{account.created_at}</b></Col>
            <Col xs={12} md={2}>Estatus del proyecto: <b>{showStatus(account) ? (
                <span className="text-success">Respaldado</span>
              ): (
                <span className="text-danger">Sin respaldar</span>
              )}</b></Col>
          </Row>          
        )}
        <br/>
        <Row>
          <Col>
            <Button onClick={createBackup} variant="primary" disabled={disableButton}>
              Generar Respaldo
            </Button>
          </Col>
          <Col>
            <Button onClick={ () => showModal(true) } variant="secondary" disabled={disableButton}>
              Cargar Respaldo
            </Button>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Fecha y hora</th>
                  <th>Tamaño</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {backups.map((backup: any, i: number) => (
                  <tr key={i}>
                    <td>{backup.created_at}</td>
                    <td>{Sizebackup(backup.size)}</td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => getBlob(backup._id, backup.filename)}
                      >
                        Descargar
                      </Button>
                       &nbsp; &nbsp; &nbsp;
                      <Button 
                        variant="secondary"
                        onClick={() => deletebackup(backup._id)}
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>            
            </Table>
          </Col>
        </Row>
        <Modal show={modal} onHide={() => showModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Subir video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Seleccione el archivo a subir</p>
              <Row className="align-items-center">
                <Col xs={12} md={6}>
                  <Button variant="secondary" onClick={uploadFile}>
                    Seleccionar un archivo
                  </Button>
                </Col>
                <Col xs={12} md={6}>
                  {file ? file.name : ""}
                </Col>
              </Row>
              <input
                type="file"
                name="file"
                onChange={fileHandle}
                className="file-input"
                style={{"visibility":"hidden"}}
                id="file-input"
              />
              <br />
              <p>Progreso de subida</p>
              <ProgressBar now={progress} label={`${progress}%`} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => showModal(false)}>
                Cerrar
              </Button>
              <Button variant="danger" onClick={confirmUpload}>
                Subir video
              </Button>
            </Modal.Footer>
          </Modal>
          <Toast
            style={{ position: "absolute", bottom: 20, right: 20 }}
            onClose={() => setToastFlag(false)}
            show={toastFlag}
            className={ toastFlag ? "d-block" : 'd-none' }
          >
            <Toast.Header>
              <strong className="mr-auto">{toastBody.title}</strong>
            </Toast.Header>
            <Toast.Body>
              {toastBody.message}
            </Toast.Body>
          </Toast>
      </div>
    </div>
  );
}

export default Account;
