import React from 'react';
import '../../styles/dashboard.scss';

function Dashboard() {

  return (
    <div className="dashboard-container">
      <h1>DASHBOARD</h1>
    </div>
  );
}

export default Dashboard;
