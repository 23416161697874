export const blob = (url: string, options: any) => {
  function handleErrors(response: any) {
    if (response.status === 403 && !options.noRedirect) {
      delete localStorage.user;
      delete localStorage.token;
      window.location.href = "/login";
    } else if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }
  if (options["headers"]) {
    options["headers"]["Authorization"] = "Bearer " + localStorage.token;
  } else {
    options["headers"] = {
      Authorization: "Bearer " + localStorage.token,
    };
  }

  return fetch(url, options)
    .then(handleErrors)
    .then((response) => {
      return response.blob();
    })
    .catch((error) => {
      console.log(error);
      return { error: error };
    });
};
